import axios from "axios";
import React, { FormEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { Checkbox } from "./fields/Checkbox";
import { Column } from "./layout/Column";
import { Grid } from "./layout/Grid";
import { Row } from "./layout/Row";

export type Request = {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly dobDate: string;
  readonly dobMonth: string;
  readonly dobYear: string;
  readonly preferredStore: string;
  readonly state: string;
  readonly gender: string;
  readonly phoneNumber: string;
  readonly optinSms: boolean;
  // readonly anniversaryDate: string;
  readonly marketing: boolean;
  readonly country: string;
};

export type Response = {
  readonly Email: string;
  readonly Fullname: string;
  readonly IsExistingMember: boolean;
};

// const endpoint = "https://staging.api.georgjensen.online/georg-crm/new-member";
const endpoint = 'https://azurewebsitebackend-prod.azurewebsites.net/api/QrCreateMemberAU?code=RbWWYQOagKOpT/YecRQrZSpPk1SvcdpHZf1mWifEuppSMIv473BVKA==';

export const Form = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dobDate, setDobDate] = useState("");
  const [dobMonth, setDobMonth] = useState("");
  const [dobYear, setDobYear] = useState("");
  const [preferredStore, setPreferredStore] = useState("");
  const [state, setState] = useState("");
  const [gender, setGender] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [optinSms, setOptinSms] = useState(false);
  const [agree, setAgree] = useState(false);

  // const [anniversaryPartDate, setAnniversaryPartDate] = useState<string>();
  // const [anniversaryPartMonth, setAnniversaryPartMonth] = useState<string>();
  // const [anniversaryPartYear, setAnniversaryPartYear] = useState<string>("");
  // const [anniversaryDate, setAnniversaryDate] = useState("");

  // useEffect(() => {
  //   if (
  //     anniversaryPartDate &&
  //     anniversaryPartMonth &&
  //     anniversaryPartYear.length === 4
  //   ) {
  //     setAnniversaryDate(
  //       [anniversaryPartMonth, anniversaryPartDate, anniversaryPartYear].join(
  //         "/"
  //       )
  //     );
  //   } else {
  //     setAnniversaryDate("");
  //   }
  // }, [anniversaryPartDate, anniversaryPartMonth, anniversaryPartYear]);

  const [errors, setErrors] = useState<readonly string[]>([]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
      setErrors([]);

      const payload: Request = {
        firstName,
        lastName,
        email,
        dobDate,
        dobMonth,
        dobYear,
        preferredStore,
        state,
        gender,
        phoneNumber,
        optinSms,
        // anniversaryDate,
        marketing: true,
        country: "AU",
      };

      let errs: string[] = [];

      if (!agree) {
        errs.push(`You must agree to the terms and conditions.`);
      }

      if (payload.firstName.length === 0)
        errs.push(`Please provide your first name.`);
      if (payload.lastName.length === 0)
        errs.push(`Please provide your last name.`);
      if (
        payload.dobDate.length === 0 ||
        payload.dobMonth.length === 0 ||
        payload.dobYear.length < 4
      )
        errs.push(`Please provide your date of birth.`);
      if (payload.preferredStore.length === 0)
        errs.push(`Please select your preferred store.`);
      if (payload.state.length === 0) errs.push(`Please select your state.`);
      if (payload.phoneNumber.length === 0)
        errs.push(`Please provide your phone number.`);

      if (errs.length > 0) {
        setErrors(errs);
        setLoading(false);
      } else {
        try {
          await axios.post(endpoint, payload);
          history.push({
            pathname: "/thanks",
            search: new URLSearchParams({ email }).toString(),
          });
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <>
      <form
        className="container"
        onSubmit={handleSubmit}
        action={endpoint}
        method="post"
      >
        <input type="hidden" name="CDUpdated" value="Yes" />

        <section className="copy">
          <p>
            As a valued member of the Georg Jensen family, we wish to
            communicate with you in the most meaningful way, so we kindly
            request your relevant information.
          </p>

          <p>Please add your details in the fields below.</p>
        </section>

        <section className="fields">
          <Grid>
            <Row>
              <Column size={6}>
                <div className="field">
                  <input
                    type="text"
                    placeholder="First Name*"
                    name="firstname"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                  />
                </div>
              </Column>

              <Column size={6}>
                <div className="field">
                  <input
                    type="text"
                    placeholder="Last Name*"
                    name="lastname"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </div>
              </Column>
            </Row>

            <Row>
              <Column>
                <div className="field">
                  <input
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
              </Column>
            </Row>

            <Row>
              <Column>
                <p>Date of birth*</p>
              </Column>
            </Row>

            <Row>
              <Column size={4}>
                <div className="field">
                  <select
                    name="Birthday Day"
                    value={dobDate}
                    onChange={(event) => setDobDate(event.target.value)}
                  >
                    <option value={undefined}>DD</option>
                    <option value="1">01</option>
                    <option value="2">02</option>
                    <option value="3">03</option>
                    <option value="4">04</option>
                    <option value="5">05</option>
                    <option value="6">06</option>
                    <option value="7">07</option>
                    <option value="8">08</option>
                    <option value="9">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                  </select>
                </div>
              </Column>

              <Column size={4}>
                <div className="field">
                  <select
                    name="Birthday Month"
                    value={dobMonth}
                    onChange={(event) => setDobMonth(event.target.value)}
                  >
                    <option value={undefined}>MM</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
              </Column>
              <Column size={4}>
                <div className="field">
                  <input
                    type="text"
                    maxLength={4}
                    placeholder="YYYY"
                    name="dobYear"
                    value={dobYear}
                    onChange={(event) => setDobYear(event.target.value)}
                  />
                </div>
              </Column>
            </Row>

            <Row>
              <Column>
                <div className="field">
                  <select
                    value={preferredStore}
                    onChange={(event) => setPreferredStore(event.target.value)}
                    name="Preferred Store"
                  >
                    <option value="">Preferred Store*</option>
                    <option value="GAUR49">Georg Jensen Online Store</option>
                    <optgroup label="New South Wales">
                      <option value="GAUR59">
                        Georg Jensen Sydney Flagship - George St
                      </option>
                      <option value="GAUR07">Georg Jensen Chatswood</option>
                      <option value="GAUR08">
                        Georg Jensen Bondi Junction
                      </option>
                      <option value="GAUR13">
                        Georg Jensen Westfield Sydney CBD
                      </option>
                      <option value="GAUR63">
                        David Jones New South Wales Locations
                      </option>
                    </optgroup>
                    <optgroup label="Victoria">
                      <option value="GAUR61">
                        Georg Jensen Melbourne Flagship - Exhibition St
                      </option>
                      <option value="GAUR01">Georg Jensen Block Arcade</option>
                      <option value="GAUR51">Georg Jensen Emporium</option>
                      <option value="GAUR05">Georg Jensen Chadstone</option>
                      <option value="GAUR02">Georg Jensen Doncaster</option>
                      <option value="GAUR38">
                        David Jones Victoria Locations
                      </option>
                    </optgroup>
                    <optgroup label="Queensland">
                      <option value="GAUR62">Georg Jensen Brisbane</option>
                      <option value="GAUR60">Georg Jensen Pacific Fair</option>
                      <option value="GAUR55">Georg Jensen Indooroopilly</option>
                      <option value="GAUR36">
                        David Jones Queensland Locations
                      </option>
                    </optgroup>
                    <optgroup label="Australian Capital Territory">
                      <option value="GAUR22">
                        David Jones Australian Capital Territory Locations
                      </option>
                    </optgroup>
                    <optgroup label="South Australia">
                      <option value="GAUR04">Georg Jensen Adelaide</option>
                      <option value="GAUR37_DJ">
                        David Jones South Australia Locations
                      </option>
                    </optgroup>
                    <optgroup label="Western Australia">
                      <option value="GAUR09">Georg Jensen Claremont</option>
                      <option value="GAUR47">
                        David Jones Western Australia Locations
                      </option>
                    </optgroup>
                    <optgroup label="New Zealand">
                      <option value="GAUR88">
                        David Jones New Zealand Locations
                      </option>
                    </optgroup>
                  </select>
                </div>
              </Column>
            </Row>

            <Row>
              <Column size={6}>
                <div className="field">
                  <select
                    value={state}
                    onChange={(event) => setState(event.target.value)}
                    name="state"
                  >
                    <option value="">State*</option>
                    <option value="New South Wales">New South Wales</option>
                    <option value="Victoria">Victoria</option>
                    <option value="Queensland">Queensland</option>
                    <option value="Tasmania">Tasmania</option>
                    <option value="Western Australia">Western Australia</option>
                    <option value="South Australia">South Australia</option>
                    <option value="Northern Territory">
                      Northern Territory
                    </option>
                    <option value="Australian Capital Territory">
                      Australian Capital Territory
                    </option>
                  </select>
                </div>
              </Column>
              <Column size={6}>
                <div className="field">
                  <select
                    value={gender}
                    onChange={(event) => setGender(event.target.value)}
                    name="gender"
                  >
                    <option value="">Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </Column>
            </Row>

            <Row>
              <Column>
                <div className="field">
                  <input
                    type="text"
                    maxLength={16}
                    placeholder="Phone Number*"
                    name="mobile"
                    value={phoneNumber}
                    onChange={(event) => setPhoneNumber(event.target.value)}
                  />
                </div>
              </Column>
            </Row>

            <Row>
              <Column>
                <div className="field">
                  <Checkbox
                    checked={optinSms}
                    name="allowsms"
                    onChange={(value) => setOptinSms(value)}
                  />
                  Opt into SMS Communication
                </div>
              </Column>
              <Column>
                <div className="field">
                  <Checkbox
                    checked={agree}
                    name="allowsms"
                    onChange={(value) => setAgree(value)}
                  />
                  Yes, I agree to receive customised marketing content by email
                  from Georg Jensen regarding products, special offers, events,
                  competitions, invitations, news, etc. I can revoke my consent
                  anytime via a cancellation link in the message. Georg Jensen
                  will collect and process my contact information along with my
                  purchase history and behaviour information collected via
                  cookies and similar technologies like pixels, tags and other
                  identifiers. When I sign up for Georg Jensen’s customised
                  marketing content, I also accept the{" "}
                  <a
                    href="https://www.georgjensen.com/en-au/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  .
                </div>
              </Column>
            </Row>

            {/* <Row>
              <Column>
                <p>Anniversary</p>
              </Column>
            </Row> */}

            {/* <Row>
              <input type="hidden" name="Anniversary" value={anniversaryDate} />

              <Column size={4}>
                <div className="field">
                  <select
                    value={anniversaryPartDate}
                    onChange={(event) =>
                      setAnniversaryPartDate(event.target.value)
                    }
                  >
                    <option value={undefined}>DD</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                  </select>
                </div>
              </Column>
              <Column size={4}>
                <div className="field">
                  <select
                    value={anniversaryPartMonth}
                    onChange={(event) =>
                      setAnniversaryPartMonth(event.target.value)
                    }
                  >
                    <option value={undefined}>MM</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
              </Column>
              <Column size={4}>
                <div className="field">
                  <input
                    type="text"
                    placeholder="YYYY"
                    maxLength={4}
                    value={anniversaryPartYear}
                    onChange={(event) =>
                      setAnniversaryPartYear(event.target.value)
                    }
                  />
                </div>
              </Column>
            </Row> */}
          </Grid>
        </section>

        {/* <section className="copy">
          <p>THANK YOU FOR UPDATING YOUR DETAILS</p>
        </section> */}

        <button type="submit" disabled={loading}>
          Submit
        </button>

        {/* <div className="terms">
          <p>Terms and conditions apply.</p>
          <p>
            Any existing Georg Jensen member who updates their details between
            12.00am AEST, 1st May to 11.59pm AEST, 31st May, will be
            automatically entered into the draw to win.
          </p>
          <p>Winners will be drawn and contacted on 2nd June via email.</p>
        </div> */}
      </form>

      {errors.length > 0 && (
        <div className="error-modal">
          <div className="error-modal-content">
            <button
              className="modal-close-button"
              onClick={(event) => {
                event.preventDefault();
                setErrors([]);
              }}
            ></button>

            <ul>
              {errors.map((error: string) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};
