import React, { useMemo } from "react";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";

export const ThanksPage = () => {
  const location = useLocation();

  const email = useMemo(() => {
    return new URLSearchParams(location.search).get("email");
  }, [location]);

  return (
    <div className="container">
      <section className="copy">
        <p>Thank you for your details.</p>

        {email && (
          <p>
            <QRCode value={email} />
          </p>
        )}

        <a className="button" href="https://www.georgjensen.com/en-au">
          Explore Georg Jensen Online
        </a>
      </section>
    </div>
  );
};
